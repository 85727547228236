import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from '../assets/img/color-sharp.png';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

export const Skills = () => {

    var skillsInformation = [
        { title: 'Frontend',percentage: 55 },
        { title: 'Backend', percentage: 40 },
        { title: 'Web Development', percentage: 65 },
        { title: 'Python', percentage: 50 }, 
        { title: 'React Js', percentage: 40 },
        { title: 'Laravel', percentage: 85 },
        { title: 'PHP', percentage: 55 },
        { title: 'Node Js', percentage: 40},
        { title: 'SQL', percentage: 60 }, 
        { title: 'Mongo DB', percentage: 40 },
    ]

    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      return (
        <section className="skill" id="skill">
            <Container>
                <Row>
                    <Col>
                    <div className="skill-bx">
                        <h2>Skills</h2>
                        <p>My technical expertise, combined with my passion for continuous learning and collaboration, allows me to tackle challenges effectively and deliver high-quality results.</p>
                        
                        <Carousel responsive={responsive} infinite={true} className="skill-slider">
                          {skillsInformation.map((skill, index) => (
                              <div className="skill-container" key={index} style={{margin: '50px', fontWeight: '600' }}>
                                  <CircularProgressbar
                                      value={skill.percentage}
                                      text={`${skill.percentage}%`}
                                      styles={buildStyles({
                                          rotation: 0.25,
                                          strokeLinecap: 'round',
                                          textSize: '14px',
                                          pathTransitionDuration: 1,
                                          pathColor: `rgb(143, 53, 142)`,
                                          textColor: '#8F358E',
                                          trailColor: '#d6d6d6',
                                          backgroundColor: '#8F358E',
                                      })}
                                  />
                                  <h6 style={{marginTop: '20px', marginLeft: '1px' }}>{skill.title}</h6> {}
                              </div>
                          ))}
                        </Carousel>
                    </div>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-left" src={colorSharp} />
        </section>
      )

}