import { useState, useEffect } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import logo from '../assets/img/g-icon.png';
import linkedinIcon from '../assets/img/linkedin.svg';
import githubIcon from '../assets/img/github.png';

export const NavBar = () => {
    const [activeLink,  setActiveLink] = useState('home');
    const [scrolled, seScrolled] = useState(false);

    useEffect (() => {
        const onScroll = () => {
            if(window.scrollY > 50) {
                seScrolled(true)
            } else {
                seScrolled(false)
            }
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const onUpdateActiveLink = (value) => { setActiveLink(value) }

    return (
        <Navbar expand="lg" collapseOnSelect className={scrolled ? 'scrolled' : ''}>
        <Container>
            <Navbar.Brand href="#home">
                <img src={logo} alt='Logo'></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
                <span className='navbar-toggler-icon'></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                <Nav.Link href="#skill" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                <Nav.Link href="#timeline" className={activeLink === 'timeline' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('timeline')}>Timeline</Nav.Link>
                <Nav.Link href="#achivements" className={activeLink === 'achivements' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('achivements')}>Achivements</Nav.Link>
                <Nav.Link href="#connect" className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}>Contact</Nav.Link>
            </Nav>
            <span className='navbar-text'>
                <div className='social-icon'>
                    <a href='https://linkedin.com/in/gagandeepdasskaur' target='_blank'><img src={linkedinIcon} alt=''></img></a>
                    <a href='https://github.com/gagandsk' target='_blank'><img src={githubIcon} alt=''></img></a>
                </div>
            </span>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    );
}