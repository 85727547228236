export const Achivements = () => {

  return (
    <section className="project" id="achivements">

    <h1 className="text-center mb-5">Achivements</h1>

    <div className="main-pro">
          <div className="about-me">
              <h1>+19</h1>
              <h5>Completed Projects</h5>
          </div>

          <div className="about-me">
              <h1>+3</h1>
              <h5>Years of Experience</h5>
          </div>

          <div className="about-me">
              <h1>+5</h1>
              <h5>Back-end technologies</h5>
          </div>

          <div className="about-me">
              <h1>+4</h1>
              <h5>Front-end technologies</h5>
          </div>

          <div className="about-me">
              <h1>+3</h1>
              <h5>Frameworks</h5>
          </div>

    </div>            
          
    </section>
  );
  
};
