import { Container, Row, Col } from "react-bootstrap"

export const Footer = () => {
    return (

        
        <footer className="footer">
            <Container>
                <Row className="align-item-center">
                    <Col sm={12} className="text-center text-sm-center">
                        <p>© 2024 CopyRight.</p>
                        <p>My power level is over 9000... and so are my rights! All Rights Reserved by <strong>Gagan</strong>.</p>
                        <p>Based in Barcelona</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}