import React from 'react';
import '../Timeline.css';

import reactjsimg from '../assets/img/reactjsimg.png';
import nodejsimg from '../assets/img/nodejsimg.png';
import berrly from '../assets/img/berrly.png';
import platziIcon from '../assets/img/platzi.png'
import mongodbimg from '../assets/img/mongodbimg.png';


export const Timeline = () => {

    return (

        <div className='main-timeline' id="timeline">

            <h1>Chronicles of Gagan</h1>

            <div className='timeline'>


            <div className='timeline-container  left-container'>

                <img src={platziIcon} alt='Platzi' />

                <div className='text-box platzicard'>

                    <h2 className='time-line-title tagline'> Platzi </h2><br></br>

                    <small> Present </small><br></br>

                    <p>To this day, I continue expanding my knowledge. After the past three years of experience, I have identified my focus in the area of <strong>Data Science</strong>. Therefore, I am pursuing this specialization through <strong>Platzi</strong>, where I am developing skills in data analysis.</p>

                    <span className='left-container-arrow'></span>

                </div>

            </div>

                <div className='timeline-container  right-container'>

                    <img src={reactjsimg} alt='React JS' />

                    <div className='text-box reactjscard'>

                        <h2 className='time-line-title tagline'> Advancement in Technologies </h2><br></br>

                        <small>2023 - Present </small><br></br>

                        <p>In 2023, I focused on improving scalable systems and real-time applications. I continued developing my skills in React.js and deepened my experience with Node.js and MongoDB to enhance project efficiency.</p>

                        <span className='left-container-arrow'></span>

                    </div>

                </div>



                <div className='timeline-container  left-container'>

                    <img src={nodejsimg} alt='Node JS' />

                    <div className='text-box'>


                        <h2 className='time-line-title tagline'> ADmira (Digital Signage)</h2><br></br>

                        <small> Front-end and Backend Developer | 2022 </small><br></br>

                        <p>
                            By 2022, I took on a more advanced role at <strong>Admira (Digital Signage)</strong> as a Front-end and Backend Developer. During this time I had my first interaction with <strong>React.js</strong>.
                        </p>

                        <span className='right-container-arrow'></span>

                    </div>

                </div>


                <div className='timeline-container  right-container'>

                    <img src={berrly} alt='Express JS' />

                    <div className='text-box'>

                        <h2 className='time-line-title tagline'>Berrly </h2><br></br>

                        <small> Front-end Developer | 2021 </small><br></br>

                        <p>
                            In 2021, I <strong>joined Berrly</strong> as a Web Developer, where I focused on front-end development, working on the company’s CRM system. This role allowed me to enhance my problem-solving skills by implementing new features, improving the user interface and experience.
                        </p>

                        <span className='left-container-arrow'></span>

                    </div>

                </div>

                <div className='timeline-container  left-container'>

                    <img src={mongodbimg} alt='MongoDB' />

                    <div className='text-box'>

                        <h2 className='time-line-title tagline'> Growth and Professional Improvement </h2><br></br>

                        <small> 2019 - 2020 </small>

                        <p>
                            In 2020, I focused on growth and professional improvement, expanding my skillset by working with <strong>PHP</strong> and <strong>MySQL</strong> databases.</p>

                        <span className='right-container-arrow'></span>

                    </div>

                </div>
            </div>

        </div>
    );
};